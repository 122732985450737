/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React, {
    useCallback,
    useEffect,
    useContext,
    useMemo,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICCustomDropdownSelect } from 'gw-capability-quoteandbind-common-react';
import { useTranslator } from '@jutro/locale';
import cx from 'classnames';
import metadata from './WMICSpecialCoverageCA.metadata.json5';

import styles from './WMICSpecialCoverageCA.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './WMICSpecialCoverageCA.messages';
import { Button } from '@jutro/legacy/components';
import { 
    CONSTANTS, WMICRichTextUtil, WMICFeatureFlagUtil
} from 'wmic-portals-utils-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
dayjs.extend(customParseFormat);

const scheduledItemsMap = {
    Jewelry: 'Jewelry_wmic',
    Silverware3_wmic: 'Silverware_wmic',
    Electronics_wmic: 'Electronics_wmic',
    HO_CreditCardCov: 'Credit Cards',
    HO_IncrLmtBusPropertyCov: 'Business Property',
    HO_EarthquakeCov: 'Earthquake',
    Firearms_wmic: 'Firearms_wmic',
    Securities_wmic: 'Securities_wmic',
    Money_wmic: 'Money_wmic'
};

const defaultValues = {
    Jewelry: 'Extended Jewelry',
    Silverware3_wmic: 'Silverware',
    Electronics_wmic: 'Portable Electronics',
    Firearms_wmic: 'Firearms',
    Money_wmic: 'currencyValue',
    Securities_wmic: 'Securities',
};

const SPECIAL_TYPE = 'special';
const OPTIONAL_TYPE = 'optional';

const SECURITIES_COVERAGE = 'Securities_wmic';

// set a default value for securities coverage (RQB-1359)
const defaultSecuritiesCoverage = '1600_wmic';

// eslint-disable-next-line no-unused-vars
const specialCoverageMasterList = ['Jewelry', 'Silverware3_wmic', 'Firearms_wmic', 'Money_wmic', 'Securities_wmic', 'Electronics_wmic'];
const optionalCoverageMasterList = ['HO_CreditCardCov', 'HO_EarthquakeCov', 'HO_IncrLmtBusPropertyCov', 'HO_PermIncOccResPremCov', 'HO_BusinessPursuitsTeacherCov', 'HO_BusinessPursuitsSalespersonCov', 'HO_BusinessPursuitsClericalCov'];

function translateTypeValue(originalTypeValue, originalName) {
    let typeValue = _.get(scheduledItemsMap, `${originalTypeValue}`);
    if (_.isEmpty(typeValue)) {
        typeValue = originalName;
    }
    return typeValue;
}

function getArrayPath(item) {
    let path = '';
    switch (item.coverageType) {
        case SPECIAL_TYPE:
            path = 'lobData.homeowners.offerings.value[0].coverages.specialLimitOfLiabilities';
            break;
        case OPTIONAL_TYPE:
            path = 'lobData.homeowners.offerings.value[0].coverages.additionalCoverages';
            break;
        default:
    }
    return path;
}

/*
* Gets the array index of the element in its VM array given
* its index in the cov table (that potentially contains a mix of special and optional coverages)
* For a specialty coverage returns the index of the element in this array:
*      lobData.homeowners.offerings.value[0].coverages.specialLimitOfLiabilities
* For an optional coverage returns the index of the element in this array:
*      lobData.homeowners.offerings.value[0].coverages.additionalCoverages
*/
function getItemIndexInVmArray(tableDataArray, itemIndexInTableDataArray, submissionVM = undefined) {
    const item = tableDataArray[itemIndexInTableDataArray];
    switch (item.coverageType) {
        case SPECIAL_TYPE:
            return tableDataArray.filter((cov, i) => i < itemIndexInTableDataArray && cov.coverageType === item.coverageType).length;
        case OPTIONAL_TYPE:
            const optArray = _.get(submissionVM, getArrayPath(item), []);
            return optArray.findIndex((cov) => cov.publicID === item.articlePropType);
        default:
            return undefined;
    }
};

function getArticlePropTypePath(tableDataArray, itemIndexInTableDataArray, submissionVM) {
    let path = '';
    const index = getItemIndexInVmArray(tableDataArray, itemIndexInTableDataArray, submissionVM);
    const item = tableDataArray[itemIndexInTableDataArray];
    switch (item.coverageType) {
        case SPECIAL_TYPE:
            path = `${getArrayPath(item)}[${index}].type`;
            break;
        case OPTIONAL_TYPE:
            path = `${getArrayPath(item)}[${index}].publicID`;
            break;
        default:
    }
    return path;
}

function getAddlLimitVMPath(tableDataArray, itemIndexInTableDataArray, submissionVM = undefined) {
    let path = '';
    let index;
    const item = tableDataArray[itemIndexInTableDataArray];
    switch (item.coverageType) {
        case SPECIAL_TYPE:
            index = getItemIndexInVmArray(tableDataArray, itemIndexInTableDataArray);
            path = `lobData.homeowners.offerings.children[0].coverages.specialLimitOfLiabilities.children[${index}].additionalLimit`;
            break;
        case OPTIONAL_TYPE:
            index = getItemIndexInVmArray(tableDataArray, itemIndexInTableDataArray, submissionVM);
            path = `lobData.homeowners.offerings.children[0].coverages.additionalCoverages.children[${index}].terms.children[0].chosenTerm`;
            break;
        default:
    }
    return path;
}

function getAddlLimitPath(tableDataArray, itemIndexInTableDataArray, submissionVM) {
    const item = tableDataArray[itemIndexInTableDataArray];
    const index = getItemIndexInVmArray(tableDataArray, itemIndexInTableDataArray, submissionVM);
    let path = '';
    switch (item.coverageType) {
        case SPECIAL_TYPE:
            path = `${getArrayPath(item)}[${index}].additionalLimit`;
            break;
        case OPTIONAL_TYPE:
            path = `${getArrayPath(item)}[${index}].terms[0].chosenTerm`;
            break;
        default:
    }
    return path;
}
function WMICSpecialCoverageCA(props) {
    const {
        submissionVM,
        updateCoverage,
        validCoverages
    } = props;
    const { userInfo: authUserData } = useAuthentication();

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [tableData, setTableData] = useState([]);
    const [coverageTypes, setCoverageTypes] = useState([]);
    const [defaultAddlLimitValues, setDefaultAddlLimitValues] = useState(undefined);
    const additionalCovs = _.get(submissionVM, 'lobData.homeowners.offerings.value[0].coverages.additionalCoverages', []);
    const specialLimitCovs = _.get(submissionVM, 'lobData.homeowners.offerings.value[0].coverages.specialLimitOfLiabilities', []);
    const serviceInfo = submissionVM.serverInfo_WMIC.value;
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [ldFlags, setLdFlags] = useState({});
    const [earthquakeCovAvailable, setEarthquakeCovAvailable] = useState(true);

    const initFeatureFlags = async () => {
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    const createItem = useCallback((typeValue, limit = '2000') => {
        const covType = optionalCoverageMasterList.includes(typeValue) ? OPTIONAL_TYPE : SPECIAL_TYPE;

        const newItem = {
            articlePropType: typeValue,
            additionalLimit: typeValue === SECURITIES_COVERAGE? defaultSecuritiesCoverage : limit,
            coverageType: covType
        }

        return newItem;
    }, []);

    const addCoverage = useCallback(
        (covParams) => {
            const { type, limit, backNav } = covParams;
            const newItem = createItem(type, limit);
            const arrayPath = getArrayPath(newItem);
            const covArray = _.get(submissionVM, arrayPath, []);

            setCoverageTypes((prevCoverageTypes) => { 
                prevCoverageTypes.push(newItem.articlePropType);
                return prevCoverageTypes 
            });
            
            if (backNav !== true) {
                if (newItem.coverageType === SPECIAL_TYPE) {
                    covArray.push({ type: type, additionalLimit: limit });
                } else {
                    const covArrayIndex = covArray.findIndex((elem) => elem.publicID === type);
                    covArray[covArrayIndex].selected = true;
                }
                updateCoverage(covArray, arrayPath, coverageTypes);
            }

            setTableData((prevTableData) => { 
                prevTableData.push(newItem);
                return prevTableData
            });
            
        }, [submissionVM, tableData, updateCoverage, defaultAddlLimitValues]
    );


    /*
    Initializes coverages that have been previously selected when this
    page is reached on back navagation.
    */
    const initCoverages = useEffect(
        () => {
            //special limit covs (special)
            if (specialLimitCovs !== undefined) {
                for (const [covIndex, cov] of specialLimitCovs.entries()) {
                    const initCov = {
                        type: cov.type,
                        limit: cov.additionalLimit,
                        backNav: true
                    }
                    addCoverage(initCov);
                }
            }

            //additional covs (optional)
            for (const [covIndex, cov] of additionalCovs.entries()) {
                if(cov?.selected === true) {
                    const initCov = {
                        type: cov.publicID,
                        limit: cov.terms[0].chosenTermValue,
                        backNav: true
                    }
                    addCoverage(initCov);
                }
            }
        }, []
    )

    const callSetIdFlags = useEffect(() => {
        initFeatureFlags();
    }, [initFeatureFlags])

    const determineEarthQuakeCovAvailabiity = useEffect(
        () => {
            const params = {
                featureName: featureFlags.HOEARTHQUAKECOV,
                regionCode: submissionVM.region_WMIC.value.code,
                serviceInfo
            };
            const response = WMICFeatureFlagUtil.queryAvailabilityQB(ldFlags, params);
            //Logic for earthquake availability
            //Earthquake coverage wont be avaiable after the effective date of policy will be 
            //on or after covRemovalDate in feature flag - operational-ceaearthquakecov
            if (response.isAvailable === true && response.effectiveDate !== undefined) {
                const serverTimeOffset = serviceInfo ? serviceInfo.timezoneOffset : '-0500';
                // When there is an effective date to not to show earthquake coverag, check if the effective date is on or after the date
                const formatEffDate = new Date(`${submissionVM.baseData.periodStartDate.value.year}-${submissionVM.baseData.periodStartDate.value.month + 1}-${submissionVM.baseData.periodStartDate.value.day} `)
                const effectiveDateMoment = formatEffDate ? dayjs(formatEffDate) : dayjs();
                const outageStartDateMoment = dayjs(response.effectiveDate + serverTimeOffset, 'MM/DD/YYYYThh:mm:ssZZ'); // 04/01/2024T08:00:00-0500
                setEarthquakeCovAvailable(effectiveDateMoment.isBefore(outageStartDateMoment, 'second'));
            } else {
                setEarthquakeCovAvailable(true);
            }
            
        }, [featureFlags, submissionVM, serviceInfo, WMICFeatureFlagUtil, ldFlags, setEarthquakeCovAvailable, dayjs]
    );

    const addDefaultCoverage = () => {
        const initCov = {
            type: 'Jewelry',
            limit: '2000',
            backNav: false
        }
        addCoverage(initCov);
    }

    //Function decide whether we have to show earthquake covergae and related static text 
    //for California jurisdiction after the CEA policy effective date.
    const hideEarthquakeCov = useCallback(
        () => {
            return submissionVM.region_WMIC.value.code === CONSTANTS.JURISDICTIONS.CA && earthquakeCovAvailable !== undefined && !earthquakeCovAvailable 
        }, [submissionVM, earthquakeCovAvailable, CONSTANTS]
    );

    // gets the available coverages to chose from the dropdown
    const getArticleOrPropertyAvailableValues = useMemo(() => {
        const { additionalCoverages } = submissionVM.lobData.homeowners.offerings.value[0]
            .coverages;
        const typeList = viewModelService.create(
            {},
            'pc',
            'wmic.edge.us.capabilities.policyjob.lob.homeownersHOE.coverages.dto.ScheduledItemDTO'
        );

        const specialLimitCoveragesList = typeList.type.aspects.availableValues
            .map((typeCode) => {
                return {
                    code: typeCode.code,
                    name: translator({
                        id: `quoteandbind.common.views.quote-details.coverage-name.${typeCode.code}`,
                        defaultMessage: _.get(defaultValues, `${typeCode.code}`)
                    })
                };
            });

        const additionalCoverageList = hideEarthquakeCov() ? additionalCoverages.reduce(function(covs, additionalCoverage) {
            if (additionalCoverage.publicID !== 'HO_EarthquakeCov') {
                var result = {
                                code: additionalCoverage.publicID,
                                name: translateTypeValue(additionalCoverage.publicID, additionalCoverage.name)
                            };
                covs.push(result);
            }
            return covs;
        }, []) : additionalCoverages.map(
                (coverage) => {
                    return {
                        code: coverage.publicID,
                        name: translateTypeValue(coverage.publicID, coverage.name)
                    };
                }
            );
        return specialLimitCoveragesList.concat(additionalCoverageList);
    }, [submissionVM, translator, viewModelService, earthquakeCovAvailable]);

    const getAddlLimitAvailableValues = useCallback(
        (tableDataArray, itemIndexInTableDataArray) => {
            const item = tableDataArray[itemIndexInTableDataArray];
            let availableValues = [];
            
            switch (item.coverageType) {
                case SPECIAL_TYPE:
                    const itemPath = getAddlLimitVMPath(tableDataArray, itemIndexInTableDataArray);
                    const selectedValue = _.get(submissionVM, itemPath);

                    let filters = [];
                    if (selectedValue) {
                        filters = selectedValue.value ? selectedValue.value.typelist.filters : [];
                    }

                    const originalTypeValue = _.get(submissionVM, getArticlePropTypePath(tableDataArray, itemIndexInTableDataArray, submissionVM));
                    const typeValue = translateTypeValue(originalTypeValue);
                    const typeFilter = filters.find((filter) => filter.name === typeValue);
                    if (!_.isEmpty(typeFilter)) {
                        availableValues = typeFilter.codes;
                    }
                    availableValues = availableValues.map((typeCode) => {
                        return {
                            code: typeCode.code,
                            name: translator({
                                id: typeCode.name,
                                defaultMessage: typeCode.name
                            })
                        };
                    });
                    break;
                case OPTIONAL_TYPE:
                    const array = _.get(submissionVM, getArrayPath(item));
                    availableValues = _.get(array.find((c) => c.publicID === item.articlePropType), 'terms[0].options');
                    break;
                default:
            }
            return availableValues;
        }, [submissionVM, translator, defaultAddlLimitValues, setDefaultAddlLimitValues]
    );

    const deleteRow = useCallback(
        (tableDataArray, itemIndexInTableDataArray) => {
            const item = tableDataArray[itemIndexInTableDataArray];
            const arrayVmPath = getArrayPath(item);
            const arrayVm = _.get(submissionVM, arrayVmPath);
            coverageTypes.splice(itemIndexInTableDataArray, 1);
            if (item.coverageType === SPECIAL_TYPE) {
                const specialTypeElementIndex = getItemIndexInVmArray(tableDataArray, itemIndexInTableDataArray);
                arrayVm.splice(specialTypeElementIndex, 1);
                updateCoverage(arrayVm, arrayVmPath, coverageTypes);
            } else if (item.coverageType === OPTIONAL_TYPE) {
                const optionalTypeElementIndex = getItemIndexInVmArray(tableDataArray, itemIndexInTableDataArray, submissionVM);
                updateCoverage(false, `${arrayVmPath}[${optionalTypeElementIndex}].selected`, coverageTypes);
            }

            tableData.splice(itemIndexInTableDataArray, 1);
            setTableData(tableData);
            setCoverageTypes(coverageTypes);
        }, [submissionVM, tableData, updateCoverage]
    );

    const updateSpecialCoverage = useCallback(
        (value, path, index) => {
            deleteRow(tableData, index);
            const newItem = createItem(value);
            coverageTypes.splice(index, 0, newItem.articlePropType);
            tableData.splice(index, 0, newItem);
            
            switch (newItem.coverageType) {
                case SPECIAL_TYPE:
                    const vmArray = _.get(submissionVM, getArrayPath(newItem));
                    const specialCovIndex = getItemIndexInVmArray(tableData, index, submissionVM);
                    vmArray.splice(specialCovIndex, 0, { type: value, additionalLimit: newItem.additionalLimit });
                    updateCoverage(vmArray, getArrayPath(newItem), coverageTypes);
                    break;
                case OPTIONAL_TYPE:
                    const optionalCovIndex = getItemIndexInVmArray(tableData, index, submissionVM);
                    updateCoverage(true, `${getArrayPath(newItem)}[${optionalCovIndex}].selected`, coverageTypes);
                    break;
                default:
            }            

            setTableData(tableData);
            setCoverageTypes(coverageTypes);
        },
        [deleteRow, submissionVM, tableData, updateCoverage, defaultAddlLimitValues]
    );

    const getArticleOrPropertyDropdown = useCallback(
        (item, itemIndexInTableDataArray) => {
            const itemPath = getArticlePropTypePath(tableData, itemIndexInTableDataArray, submissionVM);
            const selectedValue = _.get(submissionVM, itemPath);
            return (
                <>
                    <WMICCustomDropdownSelect
                        id={`articleOrPropertyDropdown${itemIndexInTableDataArray}`}
                        value={selectedValue}
                        availableValues={getArticleOrPropertyAvailableValues}
                        onValueChange={(value) =>
                            updateSpecialCoverage(value, itemPath, itemIndexInTableDataArray)
                        }
                        alwaysShowPlaceholder={false}
                        hideLabel
                    />
                    {selectedValue === "HO_EarthquakeCov" && (
                        <p className={`${styles.coverageTooltip} ${styles.customTooltip} mt15`}>
                            <i
                                className="fa fa-question-circle fa-custom-2x fa-pull-left"
                                aria-hidden="true"
                            />
                            {translator(messages.earthquakeMessage)}
                        </p>
                    )}
                    {selectedValue === "HO_IncrLmtBusPropertyCov" && (
                        <p className={`${styles.coverageTooltip} ${styles.customTooltip} mt15`}>
                            <i
                                className="fa fa-question-circle fa-custom-2x fa-pull-left"
                                aria-hidden="true"
                            />
                            {translator(messages.businessMessage)}
                        </p>
                    )}
                </>
            );

        }, [getArticleOrPropertyAvailableValues, submissionVM, updateSpecialCoverage]
    );

    const getAddlLimitValue = useCallback(
        (tableDataArray, itemIndexInTableDataArray) => {
            const path = getAddlLimitPath(tableDataArray, itemIndexInTableDataArray, submissionVM);
            const value = _.get(submissionVM, path);
            const availableValues = getAddlLimitAvailableValues(tableDataArray, itemIndexInTableDataArray);
            const item = tableDataArray[itemIndexInTableDataArray];
            const findValue = availableValues.find((availableValue) => availableValue.code === value);
            const limitValue = _.isNil(findValue) ? _.get(availableValues, '[0].code') : value;
            if (limitValue !== value) {
                _.set(submissionVM, path, limitValue);
                setTableData((prevTableData) => {
                    const tableDataElementIndex = prevTableData.findIndex((elem) => elem.articlePropType === item.articlePropType);
                    prevTableData[tableDataElementIndex].additionalLimit = limitValue;
                    return prevTableData;
                })

                
            }
            return limitValue;
        }, [getAddlLimitAvailableValues, submissionVM]
    );

    const getAddlLimitDropdown = useCallback(
        (item, itemIndexInTableDataArray) => {
            const itemPath = getAddlLimitVMPath(tableData, itemIndexInTableDataArray, submissionVM);

            return (
                <WMICCustomDropdownSelect
                    id={`addlLimit${itemIndexInTableDataArray}`}
                    value={getAddlLimitValue(tableData, itemIndexInTableDataArray)}
                    availableValues={getAddlLimitAvailableValues(tableData, itemIndexInTableDataArray)}
                    onValueChange={(value) => {                        
                        updateCoverage(value, itemPath)}}
                    disabled={item.articlePropType === "HO_EarthquakeCov"}
                    alwaysShowPlaceholder={false}
                    hideLabel
                />
            );
        }, [getAddlLimitAvailableValues, getAddlLimitValue, updateCoverage]
    );

    const getDeleteButton = useCallback(
        (item, index) => {
            return (
                <>
                    <div className="wmicShowSmallerThanTabletPortrait">
                        {translator({
                            id: 'quoteandbind.ho.directives.ho-additional-coverages.Delete',
                            defaultMessage: 'Delete'
                        })}
                    </div>
                    <Button
                        id="delete"
                        icon="delete_outline"
                        onClick={() => deleteRow(tableData, index)}
                        className={styles.wmicDeleteButton}
                    />
                </>
            );
        }, [deleteRow]
    );

    const resolvers = {
        resolveCallbackMap: {
            addCoverage: addDefaultCoverage,
            getArticleOrPropertyDropdown: getArticleOrPropertyDropdown,
            getAddlLimitDropdown: getAddlLimitDropdown,
            getDeleteButton: getDeleteButton
        },
        resolveComponentmap: {
            WMICButton
        }
    };

    const overrideProps = {
        caOptionalCoveragesTable: {
            data: tableData,
            className: cx('gw-table qnb-coverages', styles.wmicSpecialLimitsTable)
        },
        coverageError: {
            visible: !validCoverages,
            className: cx('error-inline', styles.customCoverageError),
        },
        earthquakeCovStaticLabel: {
            visible: tableData.length > 0 && hideEarthquakeCov(),
            className: cx(styles.wmiclabelStyle),
            content: WMICRichTextUtil.translateRichText(translator(messages.earthquakeLabel))
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentmap}
        />
    );
}

WMICSpecialCoverageCA.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateCoverage: PropTypes.func.isRequired,
    validCoverages: PropTypes.bool
};

export default WMICSpecialCoverageCA;